<template>
  <v-form class="fill-height" @submit.prevent>
    <gw-form-observer
      :disabled-save-action="!arUpdateRates.length"
      :loading="bLoading"
      hide-cancel-action
      hide-top-actions
      @save="onSaveRates"
    >
      <company-currency-rates
        :key="`company.${obCompany.id}`"
        :auto-save="false"
        :value="obCompany"
        only-info
        @update:rate="onChangeRate"
      />
    </gw-form-observer>
  </v-form>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import CompaniesMixin from "@/modules/companies/mixins/CompaniesMixin";
import CompanyCurrencyRates from "@/modules/companies/components/tabs/CurrenciesRates.vue";
import { AppModule } from "@/store/app";
import type { CurrencyRate } from "@planetadeleste/vue-mc-gw";
import { findIndex, forEach } from "lodash";
import { ConfigModule } from "@/store/config";

@Component({
  components: { CompanyCurrencyRates },
})
export default class CurrencyRates extends Mixins(CompaniesMixin) {
  bLoading = false;
  arUpdateRates: CurrencyRate[] = [];

  get obCompany() {
    return AppModule.company;
  }

  onChangeRate(obRate: CurrencyRate) {
    const sIndex = findIndex(this.arUpdateRates, (obItem) => {
      return obItem.currency_id === obRate.currency_id;
    });

    if (sIndex !== -1) {
      this.arUpdateRates.splice(sIndex, 1, obRate);
    } else {
      this.arUpdateRates.push(obRate);
    }
  }

  async onSaveRates() {
    if (!this.arUpdateRates.length) {
      return;
    }

    let sCount = 0;
    this.bLoading = true;

    forEach([...this.arUpdateRates], async (obItem, idx) => {
      try {
        await obItem.store();
        this.arUpdateRates.splice(idx, 1);
      } catch (e) {
        console.error(e);
      } finally {
        sCount++;

        if (sCount >= this.arUpdateRates.length - 1) {
          this.bLoading = false;
          await ConfigModule.loadCompanyCurrencyRates(true);
        }
      }
    });
  }
}
</script>
